import React from 'react';

import featuredImage from './assets/featuredImage.jpg';
import mainImage from './assets/main-image.svg';
import whitePaperPdf from './assets/white-paper-ux-design-checklist.pdf';

export const lpProps = {
  seoProps: {
    title: 'UX Design for Network Application Checklist | CodiLime',
    description:
      'Get the practical checklist that will help you get started with UX design for network applications. Learn about the 8 things you can’t skip in UX for the network industries.',
    featuredImage: featuredImage,
  },
  headerProps: {
    title: 'The UX design of your networking application',
    id: 'ux-design-checklist',
  },
  formProps: {
    redirectUrl:
      'https://resources.codilime.com/hubfs/White%20papers%20and%20case%20studies/CodiLime%20White%20Paper%20-%20UX%20checklist.pdf',
    policyInfoType: 'type3',
    mainTitle: 'Grab your free UX checklist!',
    id: 'ux-design-checklist-form',
    formId: 'f356fa37-380e-492f-8792-74ccdf2f470a',
    whitePaperPdf,
    thankYouMessage: (
      <>
        <h3>Thank you for submitting the form!</h3>
        <p>
          Your free checklist is already waiting for you. You can read it on the spot or download it for later.
          <br />
          <br />
          If you have any questions, let us know.
        </p>
      </>
    ),
  },
  learnAboutProps: {
    youWillGetToKnow: [
      'The role of UX design in designing network applications',
      'Eight things you can’t skip when choosing a design partner',
      'If you are ready to design network applications ',
    ],
    summary:
      'Get the practical checklist that will help you get started with UX design for network applications. Capitalize on CodiLime’s 11 years of experience designing digital products for telcos and the network industries.',
    promise: 'With our checklist you will learn:',
    cta: (
      <>
        Fill in the form and get your <strong>free UX design checklist</strong> now!
      </>
    ),
    imageProps: {
      src: mainImage,
      alt: 'UX design checklist',
    },
  },
};
